import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

const Advantages = () => {
  const [config, setConfig] = useState({ base_image_url: '' });

  useEffect(() => {
    fetch('/config.json') // For Gatsby, this will resolve to /config.json from the static folder
      .then((response) => response.json())
      .then((data) => setConfig(data))
      .catch((error) => console.error('Error fetching config:', error));
  }, []);

  const p1 = `${config.base_image_url}/home/about-nps/transport/1.webp`;
  const p2 = `${config.base_image_url}/home/about-nps/transport/2.webp`;
  const p3 = `${config.base_image_url}/home/about-nps/transport/3.webp`;
  const p4 = `${config.base_image_url}/home/about-nps/transport/4.webp`;
  const p5 = `${config.base_image_url}/home/about-nps/transport/5.webp`;
  const p6 = `${config.base_image_url}/home/about-nps/transport/6.webp`;

  const mockLeftGrid = [
    { image: p2 },
    { image: p3 },
  ];

  const mockMiddleGrid = [
    { image: p4 },
    { image: p5 },
  ];

  const mockRightGrid = [
    { image: p1 },
    { image: p6 },
  ];

  const Column = ({ data }) => {
    const theme = useTheme();

    return (
      <Box>
        {data.map((item, i) => (
          <Box
            key={i}
            sx={{
              marginBottom: { xs: 2, sm: 3 },
              '&:last-child': { marginBottom: 0 },
            }}
          >
            <Box
              boxShadow={1}
              sx={{
                position: 'relative',
                overflow: 'hidden',
                borderRadius: 2,
                '&:hover': {
                  '& img': { transform: 'scale(1.2)' },
                  '& .portfolio-massonry__main-item': { bottom: 0 },
                },
                '& .lazy-load-image-loaded': {
                  display: 'flex !important',
                },
              }}
            >
              <Box
                component={LazyLoadImage}
                height={1}
                width={1}
                src={item.image}
                alt="..."
                effect="blur"
                maxHeight={{ xs: 400, sm: 600, md: 1 }}
                sx={{
                  transition: 'transform .7s ease !important',
                  transform: 'scale(1.0)',
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark'
                      ? 'brightness(0.7)'
                      : 'none',
                }}
              />
              <Box
                position={'absolute'}
                bottom={'-100%'}
                left={0}
                right={0}
                padding={4}
                bgcolor={'background.paper'}
                className={'portfolio-massonry__main-item'}
                sx={{ transition: 'bottom 0.3s ease 0s' }}
              >
                <Box
                  component={'svg'}
                  preserveAspectRatio="none"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 1920 100.1"
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    transform: 'translateY(-90%)',
                    zIndex: 2,
                    width: 1,
                  }}
                >
                  <path
                    fill={theme.palette.background.paper}
                    d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
                  />
                </Box>
                <Typography variant={'h6'} fontWeight={700} gutterBottom>
                  {item.title}
                </Typography>
                <Typography>{item.description}</Typography>
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    );
  };

  Column.propTypes = {
    data: PropTypes.array.isRequired,
  };

  return (
    <Box>
      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <Column data={mockLeftGrid} />
        </Grid>
        <Grid item xs={12} md={4}>
          <Column data={mockMiddleGrid} />
        </Grid>
        <Grid item xs={12} md={4}>
          <Column data={mockRightGrid} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Advantages;
