import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
// import p2 from 'assests/Photos/Transport/1.jpg';
import Container from 'components/Container';

const Articles = () => {

    const [config, setConfig] = useState({ base_image_url: '' });
  
    useEffect(() => {
      fetch('/config.json') // For Gatsby, this will resolve to /config.json from the static folder
        .then(response => response.json())
        .then(data => setConfig(data))
        .catch(error => console.error('Error fetching config:', error));
    }, []);
  
    const p2 = `${config.base_image_url}/home/about-nps/transport/1.webp`;


  const theme = useTheme();
  return (
    <Box
      position={'relative'}
      sx={{
        backgroundImage:`url(${p2})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        marginTop: -13,
        paddingTop: 13,
        '&:after': {
          position: 'absolute',
          content: '" "',
          width: '100%',
          height: '100%',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          zIndex: 1,
          background: '#161c2d',
          opacity: 0.6,
        },
      }}
    >
      <Container
        zIndex={3}
        position={'relative'}
        minHeight={{ xs: 300, sm: 400, md: 600 }}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <Box>
          <Box marginBottom={2}>
            <Typography
              variant="h2"
              align={'center'}
              sx={{
                fontWeight: 700,
                color: theme.palette.common.white,
              }}
            >
              Transport
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="h6"
              align={'justify'}
              sx={{
                fontWeight: 700,
                color: theme.palette.common.white,
              }}
            >
              <br />
              <br />
              Sam Tours and Travel are the service providers for school transportation.
Well-maintained, well-networked fleet of buses with trained drivers and lady attendants are provided. 
They aid in connecting the school to various parts of the city. All the buses are equipped with GPS and with Mandatory First-Aid kit and the staff are regularly trained to take care of the students while they are using the school transport
.<br />
              
            </Typography>
          </Box>
        </Box>
      </Container>
      <Box
        component={'svg'}
        preserveAspectRatio="none"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 1920 100.1"
        width={1}
        marginBottom={-1}
        position={'relative'}
        zIndex={2}
      >
        <path
          fill={theme.palette.background.paper}
          d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
        ></path>
      </Box>
    </Box>
  );
};

export default Articles;